@import 'themes';

@import '~@nebular/theme/styles/globals';

@include nb-install() {
  @include nb-theme-global();
};
/* You can add global styles to this file, and also import other style files */

nb-sidebar nb-menu .menu-item a {
  color: white !important;
}

.actions-container {
  margin-bottom: 2rem;
  align-items: flex-start;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.flex {
  display: flex;
}

.flex-2 {
  flex: 2;
}

.flex-1 {
  flex: 1;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;

}

ng2-smart-table tr {
  cursor: pointer;
}

.pull-right {
  float: right;
}

.section-group  {
  margin-right: 1rem;
}

.absolute-100 {
  position: absolute;
  z-index: 100;
}

.mt-1 {
  margin-top: 1rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mr-1 {
  margin-right: 1rem;
}

.ml-1 {
  margin-left: 1rem;;
}

.gap-1 {
  gap: 1rem;
}

hr {
  border-color: whitesmoke;
  border-style: solid;
}

a.ng2-smart-action.ng2-smart-action-custom-custom {
  align-items: flex-end !important;
  margin-top: 1rem
;
}

.gap-1 {
  gap: 1rem;
}

.gap-2 {
  gap: 2rem;
}

.hidden {
  visibility: hidden;
}


@media (max-width: 768px) {
  nb-tab {
    padding: 0.5rem !important;
  }
}
